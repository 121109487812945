<template>
  <v-card class="coctel-card">
    <div class="large-coctel-row">
      <v-row
        v-if="isPair"
        class="coctel-row"
        align="center"
      >
        <v-col
          md="4"
          offset="1"
          sm="12"
          xs="12"
        >
          <h2 class="text-center mixology-title">{{ value.name }}</h2>
          <h4 class="text-center grey--text">{{ content.subtitle }}</h4>
          <p
            class="text-justify"
            v-html="content.desc"
          ></p>
        </v-col>
        <v-col
          md="7"
          sm="12"
          xs="12"
        >
          <v-img
            :src="require('@/assets/mixologia/' + value.image)"
            height="450px"
            width="100%"
            contain
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!isPair"
        class="coctel-row large-coctel-row"
        align="center"
      >
        <v-col
          md="7"
          sm="12"
          xs="12"
        >
          <v-img
            :src="require('@/assets/mixologia/' + value.image)"
            height="450px"
            width="100%"
            contain
          />
        </v-col>
        <v-col
          md="4"
          sm="12"
          xs="12"
        >
          <h2 class="text-center mixology-title">{{ value.name }}</h2>
          <h4 class="text-center grey--text">{{ content.subtitle }}</h4>
          <p
            class="text-justify"
            v-html="content.desc"
          ></p>
        </v-col>
      </v-row>
    </div>
    <div class="small-coctel-row">
      <v-row
        v-if="!isPair"
        class="coctel-row"
        align="center"
      >
        <v-col
          md="4"
          offset="1"
          sm="12"
          xs="12"
        >
          <h2 class="text-center mixology-title">{{ value.name }}</h2>
          <h4 class="text-center grey--text">{{ content.subtitle }}</h4>
          <p
            class="text-justify"
            v-html="content.desc"
          ></p>
        </v-col>
      </v-row>
      <v-row
        v-if="!isPair"
        align="center"
      >
        <v-col
          md="7"
          sm="12"
          xs="12"
        >
          <v-img
            :src="require('@/assets/mixologia/' + value.image)"
            height="450px"
            width="100%"
            contain
          />
        </v-col>
      </v-row>
      <v-row
        v-if="isPair"
        class="coctel-row"
        align="center"
      >
        <v-col
          md="4"
          offset="1"
          sm="12"
          xs="12"
        >
          <h2 class="text-center mixology-title">{{ value.name }}</h2>
          <h4 class="text-center grey--text">{{ content.subtitle }}</h4>
          <p
            class="text-justify"
            v-html="content.desc"
          ></p>
        </v-col>
      </v-row>
      <v-row
        v-if="isPair"
        align="center"
      >
        <v-col
          md="7"
          sm="12"
          xs="12"
        >
          <v-img
            :src="require('@/assets/mixologia/' + value.image)"
            height="450px"
            width="100%"
            contain
          />
        </v-col>
      </v-row>
    </div>
      
    </div>
  </v-card>
</template>

<script>
  export default {
    name: 'MixologiaCoctel',
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      isPair () {
        return this.value.id % 2 === 0
      },
      content () {
        return this.value[this.$store.state.lang]
      },
    },
  }
</script>

<style scoped>
  @font-face {
      font-family: 'font-regular';
      src: url('~@/assets/fonts/font-regular.ttf');
  }
  .mixology-title{
    font-family: 'font-regular';
    font-size: 65px;
  }
  .coctel-card {
    margin-bottom: 25px;
  }
  .coctel-row {
    padding: 25px;
  }
  .text-center {
    text-align:center;
    padding-bottom: 5px;
  }
  @media only screen and (max-width: 1026px) { 
    .large-coctel-row { 
      display: none; 
    } 
  }
  @media only screen and (min-width: 1026px) { 
    .small-coctel-row { 
      display: none; 
    } 
  }
</style>
